<template>
  <v-container>
    <v-row justify="center">
      <v-col xs="12" md="6">
        <h1>Reset Password</h1>
        <br />

        <p>
          Please enter in your email address below to be sent a reset password
          link.
        </p>
      </v-col>
    </v-row>

    <v-form ref="form" @submit.prevent="onSubmit">
      <v-row justify="center">
        <v-col xs="9" md="6">
          <v-text-field :disabled="success" v-model="email" label="email address" outlined>
          </v-text-field>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col xs="9" md="6">
          <v-btn v-if="!sending && !error && !success" @click="onSubmit"
            >Submit</v-btn
          >
      
          <p v-if="sending">Loading...</p>
          <p v-if="error">
            There was an error and the request could not be completed.
          </p>
          <v-alert v-if="success" outlined prominent type="success"
            >We have received your password reset request successfully. If an
            account exists with that email, a link with a code will be sent to that address.
            Please check your email for a password reset link.</v-alert>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import API from "@/plugins/API";

export default {
  data() {
    return {
      loading: true,
      email: null,
      error: false,
      sending: false,
      success: false,
    };
  },
  methods: {
    async onSubmit() {
      this.sending = true;
      let reset_response = await API().post(
        "/api/auth/forgot-password",
        { email: this.email },
        {
          headers: {
            Authorization: null,
          },
        }
      );
      if (reset_response.status == 200) {
        this.sending = false;
        this.success = true;
      } else {
        this.error = true;
      }
    },
  },
  mounted() {},
};
</script>

<style>
</style>